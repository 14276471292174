/* Customer Profile css */

.customer-profile-wrapper {
  min-height: 100vh;
}
.edit-profile span {
  position: absolute;
  top: 8px;
  left: 0;
}
.customer-profile-wrapper .top-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.customer-profile-wrapper .controls-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 400px;
}
.customer-profile-wrapper .controls-section .add-debit,
.customer-profile-wrapper .controls-section .edit-profile {
  text-align: center;
  font-size: 1.5rem;
}
.customer-profile-wrapper .controls-section .add-debit span,
.customer-profile-wrapper .controls-section .edit-profile span {
  font-size: 0.8125rem;
}

.customer-profile-wrapper .controls-section .edit-profile .icon,
.customer-profile-wrapper .controls-section .add-debit .icon {
  cursor: pointer;
  border: 1px solid darkgray;
  border-radius: 100%;
  width: 72px;
  height: 72px;
  margin: 0 auto;
  padding-top: 17px;
}
.customer-profile-wrapper .controls-section .edit-profile .icon:hover,
.customer-profile-wrapper .controls-section .add-debit .icon:hover {
  background: #343a40;
  color: #ffffff;
}

.customer-profile-wrapper .top-section .img-section {
  width: 150px;
  position: relative;
}
.customer-profile-wrapper .top-section .img-section #photo-change {
  display: none;
}
.customer-profile-wrapper .top-section .img-section #photo-change.active {
  background: #343a40;
  opacity: 0.8;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  padding-top: 40px;
  font-size: 2.5rem;
  display: block;
}

.customer-profile-wrapper .main-wrapper {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  font-size: 0.85rem;
  align-items: flex-start;
}

.customer-profile-wrapper .main-wrapper .user-info {
  margin-left: 5px;
}

.customer-profile-wrapper .main-wrapper .user-info ul {
  list-style-type: none;
}

.img-blur {
  filter: blur(8px);
  cursor: pointer;
}
#personal-img {
  display: none;
}
.fa-camera {
  cursor: pointer;
  color: white;
}
