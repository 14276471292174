.create-product {
}

.create-product .price-section {
}
.close {
  position: absolute;
  margin: 8px;
  font-size: 16pt;
  width: 25px;
  height: 25px;
  border: 1px solid #fff;
  border-radius: 50px;
  background-color: #fff;
}
.close::before {
  content: 'x';
  padding-right: 5px;
}
.close:hover {
  color: black;
  border-color: black;
}
.btn-delete {
  color: #fff !important;
  float: left;
}
