.category img:hover {
  opacity: 0.9;
}
.category .title {
  font-size: 3rem;
  position: relative;
  top: -5rem;
  color: #fff;
  background-color: linear-gradient(45deg, #1d478b, #11689b) !important;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 0.5rem 1rem;
}
